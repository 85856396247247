@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.autoplay-carousel {
	margin: auto;
	overflow: hidden;
	position: relative;

	.slide-track {
		display: flex;
	}

	.slide {
		width: 250px;
	}
}
