@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "~swiper/src/swiper";
@import "../.styles/mixins";

.corporate-site {
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	&__photo {
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -5;

		@include gridle_state(sm) {
			background-position: left 30% top 0;
		}
	}

	&__header {
		width: 100%;
		height: 90px;
		position: absolute;
		top: 0;
		z-index: 1;
		display: flex;
		align-items: center;
		background-image: linear-gradient(to bottom, rgba(7, 42, 60, 0.9), rgba(39, 83, 128, 0));

		&__items {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin: 0 20px;

			@include gridle_state(sm) {
				justify-content: flex-end;
			}

			.shop-switcher {
				color: white;

				.icon {
					margin-right: 5px;

					@include gridle_state(sm) {
						margin-right: 10px;
					}
				}

				svg {
					width: 12px;
					height: 12px;

					@include gridle_state(sm) {
						width: 16px;
						height: 16px;
					}
				}
			}
		}
	}

	&__logo {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		height: 50px;

		.logo-brand {
			height: 50px;
			width: 150px;
		}

		@include gridle_state(md) {
			height: 70px;

			.logo-brand {
				height: 70px;
				width: 230px;
			}
		}
	}

	&__contact {
		&--mobile {
			width: 50px;
			height: 45px;
			transform: scale(0.5);
			transform-origin: 100%;

			@include gridle_state(sm) {
				display: none;
			}
		}

		&--tablet-desktop {
			display: none;

			@include gridle_state(sm) {
				display: block;
				margin-right: 20px;
				border: 1px solid white;
				border-radius: 5px;
				cursor: pointer;

				.relative-link {
					color: white;
					padding: 5px 10px;
					text-transform: uppercase;
				}

				&:hover {
					border: 1px solid $color-primary;
				}
			}
		}
	}

	&__form {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 30px;
		border-radius: 20px;
		background-color: rgba(15, 44, 5, 0.45);

		&-container {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.icon--cross {
			width: 15px;
			height: 15px;
			padding: 2px;
			position: absolute;
			top: 15px;
			right: 15px;

			&:hover {
				cursor: pointer;
			}
		}

		.search-booking-form,
		.recover-booking-data-form {
			width: 235px;
			min-height: 225px;

			@include gridle_state(sm) {
				width: 265px;
			}

			&__title {
				font-size: 1.9rem;
				color: white;
				max-width: 180px;
				margin: 0 auto;
			}

			&__intro {
				color: white;
			}

			&__title-separator {
				border-bottom: 2px solid $color-primary;
				margin: 12px auto auto;
				width: 40px;
			}

			&__link {
				a {
					&:hover {
						text-decoration-color: white;
					}
				}

				.relative-link__text {
					color: white;
				}
			}

			&__success {
				color: white;
			}
		}
	}

	&__carousel {
		width: 100%;
		margin-top: 500px;
		padding: 15px 0;

		/* stylelint-disable indentation */
		background: linear-gradient(
			90deg,
			rgba(49, 81, 95, 1) 0%,
			rgba(5, 16, 57, 0.25) 13%,
			rgba(5, 16, 57, 0.25) 87%,
			rgba(49, 81, 95, 1) 100%
		);

		@include gridle_state(sm) {
			background: linear-gradient(
				90deg,
				rgba(7, 42, 60, 1) 0%,
				rgba(5, 16, 57, 0.25) 15%,
				rgba(5, 16, 57, 0.25) 85%,
				rgba(7, 42, 60, 1) 100%
			);
		}

		@include gridle_state(md) {
			position: absolute;
			bottom: 20px;
			margin-top: 0;
		}

		&__item {
			min-width: 150px;
			height: 50px;
			margin-left: 60px;

			.logo-brand {
				width: 100%;
				height: 100%;
			}
		}
	}
}
